/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-05-16",
    versionChannel: "nightly",
    buildDate: "2024-05-16T00:05:26.436Z",
    commitHash: "ef1f6ce29bb0af881184eb1cd9c499c4ccc6e1ca",
};
